//Created by bash script
import { useEffect } from 'react';
import styles from './Canvas.module.css';

const Canvas = (props) => {
  const classes = `${styles.Canvas} ${props.className ? props.className : ''}`;
  const inlineStyles = props.style;
  let ctx;
  let c1 = [Math.random() * 350, Math.random() * 350];
  let c2 = [Math.random() * 640, Math.random() * 520];
  let c3 = [Math.random() * 800, Math.random() * 650];
  const draw = () => {
    // ctx.clearRect(0, 0, props.w, props.h); // clear canvas
    const lingrad = ctx.createLinearGradient(0, 0, 0, props.h);
    lingrad.addColorStop(0, '#0c1921');
    // lingrad.addColorStop(0, '#DA4453');
    lingrad.addColorStop(0.9, '#051015');
    // ctx.shadowOffsetX = 0;
    // ctx.shadowOffsetY = 0;
    // ctx.shadowBlur = 50;
    // ctx.shadowColor = 'rgba(250, 150, 0, 0.5)';
    ctx.fillStyle = lingrad;
    // ctx.fillStyle = '#0c1921';
    // ctx.fillStyle = '#DA4453';
    ctx.beginPath();
    ctx.arc(c1[0], c1[1], 5, 0, Math.PI * 2, true);

    ctx.moveTo(c2[0], c2[1]);
    ctx.arc(c2[0], c2[1], 5, 0, Math.PI * 2, true);

    ctx.moveTo(c3[0], c3[1]);
    ctx.arc(c3[0], c3[1], 5, 0, Math.PI * 2, true);

    c1[0] = c1[0] + Math.cos(c1[1] / 50);
    c1[1] -= 1;
    c2[0] = c2[0] + Math.cos(c2[1] / 50);
    c2[1] -= 1;
    c3[0] = c3[0] + Math.cos(c3[1] / 50);
    c3[1] -= 1;

    if (c1[1] < -50) {
      c1[1] = props.h + 50;
      c1[0] = Math.random() * (props.w + 50);
    }
    if (c2[1] < -50) {
      c2[1] = props.h + 50;
      c2[0] = Math.random() * (props.w + 50);
    }
    if (c3[1] < -50) {
      c3[1] = props.h + 50;
      c3[0] = Math.random() * (props.w + 50);
    }
    // ctx.closePath();
    // ctx.stroke();
    ctx.fill();
    window.requestAnimationFrame(draw);
  };
  useEffect(() => {
    //
    const canvas = document.getElementById(props.id);
    ctx = canvas.getContext('2d');

    window.requestAnimationFrame(draw);
  });

  return (
    <div className={classes}>
      <canvas
        style={inlineStyles}
        width={props.w}
        height={props.h}
        id={props.id}
      >
        {props.children}
      </canvas>
      {/* <CzLogo></CzLogo> */}
    </div>
  );
};
export default Canvas;

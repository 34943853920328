const propsItem = (props) => {
  return (
    <div className='propsItem'>
      <div key={`${props.title}proj${props.i}`}>
        {/*TITLE*/}
        <h3>{props.title}</h3>
        {/*IF VIDEO*/}
        {props.video &&
          props.video.map((vid, i) => (
            <video controls width={'100%'} key={`${props.title}vid${props.i}`}>
              <source src={vid.src} type='video/mp4' />
            </video>
          ))}
        {/*IF IMAGE*/}
        {props.images &&
          props.images.map((img, i) => (
            <img
              src={img.src}
              key={`${props.title}img${props.i}`}
              alt={img.alt}
            ></img>
          ))}
        {/*DESCRIPTION*/}
        <p>{props.description}</p>
      </div>
    </div>
  );
};

export default propsItem;

// import styles from "./Skills.module.css";
import List from '../ui/List';
import FlexBox from '../ui/FlexBox';
import Section from './Section';

const Skills = () => {
  const lang = ['C', 'C++', 'Java', 'GLSL', 'HTML', 'CSS', 'JS'];
  const os = ['Linux', 'Mac OS'];
  const software = ['Git', 'VS Code', 'Vim', 'GDB', 'Valgrind'];
  const frameworks = ['React', 'OpenGL', 'SDL'];
  const other = ['Web Design', 'Graphic Design', 'UX/UI', 'Illustration'];

  return (
    <Section title='Skills'>
      <FlexBox>
        <div style={{ position: 'relative' }}>
          <h2>Languages</h2>
          <List style='row' list={lang} />
        </div>

        <div style={{ maxWidth: '400px' }}>
          <h2>OS</h2>
          <List style='row' list={os} />
        </div>

        <div style={{ maxWidth: '400px' }}>
          <h2>Software</h2>
          <List style='row' list={software} />
        </div>

        <div style={{ maxWidth: '400px' }}>
          <h2>Frameworks</h2>
          <List style='row' list={frameworks} />
        </div>

        <div style={{ maxWidth: '400px' }}>
          <h2>Other</h2>
          <List style='row' list={other} />
        </div>
      </FlexBox>
    </Section>
  );
};

export default Skills;

import styles from './Contact.module.css';
import { FaLinkedinIn } from 'react-icons/fa';
import { FaGoogle } from 'react-icons/fa';
import { FaGithub } from 'react-icons/fa';
// import FlexBox from '../ui/FlexBox';
import IconMenu from '../ui/IconMenu';
import IconMenuButton from '../ui/IconMenuButton';
const Contact = () => {
  return (
    <div className={styles.Contact}>
      {/* <FlexBox> */}
      <IconMenu>
        <IconMenuButton target='_blank' href='mailto:cesargliendo@gmail.com'>
          <FaGoogle className={styles.icon} />
        </IconMenuButton>
        <IconMenuButton
          target='_blank'
          href='https://linkedin.com/in/cesarliendo'
        >
          <FaLinkedinIn className={styles.icon} />
        </IconMenuButton>
        <IconMenuButton target='_blank' href='https://github.com/cesar-gabriel'>
          <FaGithub className={styles.icon} />
        </IconMenuButton>
      </IconMenu>
    </div>
  );
};
export default Contact;

// import styles from "./Skills.module.css";
import List from '../ui/List';
import FlexBox from '../ui/FlexBox';
import Section from './Section';
import Box from '@mui/material/Box';

const Skills = () => {
  const lang = ['C', 'C++', 'Java', 'GLSL', 'HTML', 'CSS', 'JS'];
  const os = ['Linux', 'Mac OS'];
  const software = ['Git', 'VS Code', 'Vim', 'GDB', 'Valgrind'];
  const frameworks = ['React', 'OpenGL', 'SDL'];
  const other = ['Web Design', 'Graphic Design', 'UX/UI', 'Illustration'];

  return (
    <Section
      //
      style={{ backgroundColor: '#0c1921' }}
      //
    >
      {/* <FlexBox> */}
      <Box
        sx={{
          display: 'flex',
          backgroundColor: '#0c1921',
        }}
      >
        <div>
          {/* <h2>Contact</h2> */}
          <p>César Liendo</p>
          <p>cesargliendo@gmail.com</p>
          <p>github.com/cesar-gabriel</p>
          <p>Copyright 2022</p>
        </div>
      </Box>
      {/* </FlexBox> */}
    </Section>
  );
};

export default Skills;

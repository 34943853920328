//Created by bash script
import { SpeedDial } from '@mui/material';
import { FiMenu } from 'react-icons/fi';
const SpeedDialButton = (props) => {
  const actions = ['Do this', 'do that'];
  return (
    <SpeedDial
      onClick={props.onClick}
      ariaLabel='SpeedDial basic example'
      sx={{
        position: 'fixed',
        top: { xs: 'auto', sm: '0px' },
        bottom: { xs: '16px', sm: 'auto' },
        right: 16,
        '.MuiSpeedDial-fab': {
          background: 'linear-gradient(-45deg,#89216B, #DA4453)',
        },
      }}
      //   icon={<SpeedDialIcon />}
      icon={
        // <span style={{ fontSize: '1.5rem' }}>
        <FiMenu />
        // {/* </span> */}
      }
    >
      {/* {actions.map((action) => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
        />
      ))} */}
    </SpeedDial>
  );
};
export default SpeedDialButton;

//Created by bash script
import styles from './ProjectSection.module.css';

const ProjectSection = (props) => {
  const classes = `${styles.projects} ${
    props.className ? props.className : ''
  }`;
  const key = `${props.section.title}sec${props.i}`;
  return (
    <div
      onClick={() => {
        console.log(key);
      }}
      className={classes}
      key={key}
    >
      {props.children}
    </div>
  );
};
export default ProjectSection;

import './App.css';
import './index.css';
import Header from './components/Header/Header';
import Projects from './components/Projects/Projects';
import Skills from './components/Skills';
import Artworks from './components/Artworks';
import Footer from './components/Footer';
import About from './components/About';
import { Fragment } from 'react';
// import DrawerAppBar from './components/mui/DrawerAppBar';
import MyDrawer from './components/mui/MyDrawer';
import { useState } from 'react';
import ContactPage from './components/ContactPage';
import Nav from './ui/Nav';
import SVGText from './ui/SVGText';
import { Box } from '@mui/material';
import CzLogo from './components/Logo';
import Canvas from './ui/Canvas';
const menu = ['Projects', 'Artwork', 'Skills', 'About', 'Contact', 'Resumé'];

const windowWidth = window.innerWidth;
const windowHeight = window.innerHeight;

function App() {
  const logoStyle = {
    '--a': '#DA4453',
    '--b': '#89216B',
    '--a2': '#efefef',
    '--b2': '#d0d0d0',
  };

  return (
    <Fragment>
      {true && (
        <Canvas
          w={windowWidth}
          h={windowHeight}
          id={'myCanvas'}
          style={{
            position: 'absolute',
            // position: 'fixed',
            zIndex: '-1',
            width: '100%',
            height: `${windowHeight}px`,
            // backgroundColor: 'transparent',
            backgroundColor: '#051015',
          }}
        />
      )}
      <MyDrawer
        menu={menu}
        // logo={
        //   // <SVGText viewWidth={400} gradient='url("#myGradient2")'>
        //   //   César Liendo
        //   // </SVGText>
        //   <Box
        //     sx={{
        //       width: { xs: '100px' },
        //       margin: '0 auto',
        //     }}
        //   >
        //     <CzLogo style={logoStyle} />
        //   </Box>
        // }
      />
      <Header />
      <main>
        <Projects />
        {/* <Artworks /> */}
        <Skills />
        {/* <About /> */}
        {/* <ContactPage /> */}
      </main>
      <Footer />
    </Fragment>
  );
}

export default App;

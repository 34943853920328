const FlexBox = (props) => {
  const style = {
    display: 'flex',
    flexWrap: !props.nowrap ? 'wrap' : '',
    gap: '2rem',
    flexDirection: 'row',
  };
  return <div style={style}>{props.children}</div>;
};
export default FlexBox;

import styles from './Projects.module.css';
import SVGText from '../../ui/SVGText';
import ProjectSection from './ProjectSection';
import ProjectItem from './ProjectItem';
import Section from '../Section';

import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

import img from '../../images/cliendo1_6_Transformations.gif';

const projects = [
  {
    title: 'Featured Projects',
    content: [
      {
        title: 'OpenGL/GLSL',
        description: 'Various animations using GL Shading Language.',
        tags: ['OpenGL', 'GLSL', 'C++', 'JOGL'],
        // images: [
        //   {
        //     src: require('../../images/cliendo1_6_Transformations.gif'),
        //     alt: 'Rotating objects in 3D',
        //   },
        // ],
      },
      {
        title: 'Matrix Sandbox',
        description: 'Solve systems of linear equations in a DIY style.',
        tags: ['React', 'JS', 'HTML', 'CSS'],
        // video: [
        //   {
        //     src: require('../../video/matrix-demo-05.mp4'),
        //     alt: 'Demo of solving a system of linear equations.',
        //   },
        // ],
      },
      {
        title: 'Space Shooter',
        description: 'Infinite scroller space shooter game.',
        tags: ['SDL', 'C', 'C++'],
      },
      {
        title: 'Impressionist Painter',
        description:
          'Photo editor that helps create artistic rendering of pictures. Includes various style brushes with many customizable properties.',
        tags: ['C', 'C++', 'OpenGL'],
      },
      {
        title: 'Todo List App',
        description:
          'HUD where users can create and manage modules for productivity.',
        tags: ['React', 'HTML', 'CSS'],
      },
      {
        title: 'K-nearest Neighbor',
        description:
          'Given a training set, the program guesses the classification of new data by clustering to the nearest known values.',
        tags: ['CLISP'],
      },
    ],
  },
];

const Projects = () => {
  //

  const onInit = () => {
    console.log('lightGallery has been initialized');
  };

  const projectsRender = projects.map((section, i) => (
    <ProjectSection section={section} i={i} key={`section${i}`}>
      {section.content.map((project, j) => (
        <ProjectItem
          title={project.title}
          description={project.description}
          images={project.images}
          i={i}
          key={'dskfjsf' + j}
        ></ProjectItem>
      ))}
    </ProjectSection>
  ));

  // const example = (
  //   <LightGallery
  //     onInit={onInit}
  //     licenseKey={20202020200202}
  //     speed={500}
  //     plugins={[lgThumbnail, lgZoom]}
  //   >
  //     <a href='https://picsum.photos/200/300?random=1'>
  //       <img alt='img1' src='https://picsum.photos/200/300?random=1' />
  //     </a>
  //     <a href='img/img2.jpg'>
  //       <img alt='img2' src='https://picsum.photos/200/300?random=2' />
  //     </a>
  //     <a href='https://picsum.photos/400/200?random=3'>
  //       <img alt='img2' src='https://picsum.photos/200/300?random=3' />
  //     </a>
  //   </LightGallery>
  // );

  return (
    <Section title='Projects'>
      {/* {example} */}
      {projectsRender}
    </Section>
  );
};
export default Projects;

//Created by bash script
import { Box, Drawer } from '@mui/material';
import { useState } from 'react';
import { Typography } from '@mui/material';
import { Divider } from '@mui/material';
import { List, ListItem } from '@mui/material';
import { ListItemButton } from '@mui/material';
import { ListItemText } from '@mui/material';
import SpeedDialButton from './SpeedDialButton';
// import { ListItemText } from '@mui/material';
const drawerWidth = 280;

const MyDrawer = (props) => {
  const navItems = props.menu
    ? props.menu
    : ['Home', 'Gallery', 'About', 'Contact'];
  //   const { window } = props;

  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{
        //
        textAlign: 'center',
        //
        // backgroundColor: '#0c1921',
      }}
    >
      {/* <Typography variant='h6' sx={{ my: 2 }}>
        César Liendo
      </Typography> */}
      {props.logo}
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <Box component='nav'>
      <SpeedDialButton onClick={handleDrawerToggle}></SpeedDialButton>
      <Drawer
        container={container}
        variant='temporary'
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: {
            xs: 'block',
            // sm: 'none'
          },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            backgroundColor: '#0c1921',
            color: '#fff',
          },
        }}
      >
        {drawer}
      </Drawer>
    </Box>
  );
};
export default MyDrawer;

import styles from './Header.module.css';
// import AnimatedAvatar from './AnimatedAvatar';
import SubHeader from './SubHeader';
import Contact from '../Contact';
import CzLogo from '../Logo.js';
import { Box } from '@mui/material';
import SVGText from '../../ui/SVGText';
const Header = () => {
  const textColor = '#eee';
  // const textColor = '#2C5364';
  // const logoGradient = { a: '#FDC830', b: '#F37335' };
  // const logoGradient = { a: '#DA4453', b: '#89216B' };
  // const logoGradient = { a: '#44A08D', b: '#093637' };
  // const logoGradient = { a: '#ee0979', b: '#ff6a00' };
  // const logoGradient = { a: '#3a6186', b: '#89253e' };
  // const logoGradient = { a: '#203A43', b: '#2C5364' };

  // const logoStyle = {
  //   '--a': '#DA4453',
  //   '--b': '#89216B',
  // };
  const logoStyle = {
    '--a': '#DA4453',
    '--b': '#89216B',
    '--a2': '#efefef',
    '--b2': '#d0d0d0',
    filter: 'drop-shadow(2px 2px 1px #111)',
    // zIndex: '5',
  };

  return (
    <header className={`${styles.AppHeader} `}>
      <Box sx={{ width: { xs: '200px' } }}>
        <CzLogo style={logoStyle} />
      </Box>
      <Box>
        <SVGText viewWidth={400} gradient={'url("#myGradient2")'}>
          César Liendo
        </SVGText>
      </Box>
      <SubHeader>Software Developer</SubHeader>
      <Contact />
    </header>
  );
};
export default Header;

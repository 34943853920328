//Created by bash script
import styles from './Section.module.css';
import SVGText from '../ui/SVGText';
const Section = (props) => {
  const classes = `${styles.Section} ${props.className ? props.className : ''}`;
  return (
    <section className={classes} style={props.style}>
      {props.title && (
        <SVGText gradient={'url("#myGradient")'}>{props.title}</SVGText>
      )}
      {props.children}
    </section>
  );
};
export default Section;
